import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Port_Forwarding/Netgear/YoutubeVideoCardForwarding";
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - Netgear",
  "path": "/Internet_Access/Port_Forwarding/Netgear/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_Netgear.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Netgear.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Port Forwarding - Netgear' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_Netgear.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_Netgear.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/Netgear/' locationFR='/fr/Internet_Access/Port_Forwarding/Netgear/' crumbLabel="Netgear" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "netgear-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#netgear-router",
        "aria-label": "netgear router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Netgear Router`}</h2>
    <p>{`This is the 2nd step of the Port Forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`here`}</a>{` to get to Step I.`}</p>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <p>{`Netgear routers have different interfaces. Here we present two interfaces, just follow the guide to the interface that corresponds to your model.`}</p>
    <h2 {...{
      "id": "new-interface-eg-wnr-2000v5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-interface-eg-wnr-2000v5",
        "aria-label": "new interface eg wnr 2000v5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New Interface (e.g. WNR 2000v5)`}</h2>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`To access the user interface of your router, open the following address with your internet browser 192.168.1.1. The default login is username = admin and password = password. Navigate to the `}<strong parentName="p">{`Port Forwarding/Port Triggering`}</strong>{` in the `}<strong parentName="p">{`Advanced`}</strong>{` menu. Type your camera´s IP-address into the field `}<strong parentName="p">{`Server IP Address`}</strong>{`. Click on `}<strong parentName="p">{`Add`}</strong>{` to save the service.`}</p>
    <p>{`Then enter the following data in the new window:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Dienstname`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose a name for your camera, for example `}<strong parentName="td">{`Camera Input`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Protokoll`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose TCP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Externer Portbereich`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the port of your camera here, i.e. `}<strong parentName="td">{`8081`}</strong>{` for camera 1 as in step 1. If you add further cameras, then change the corresponding port for each of them (`}<strong parentName="td">{`8082`}</strong>{` for camera 2 etc.).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Interner Portbereich`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the same port here as in the previous field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Interne IP-Adresse`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the IP address of your camera here or select your camera from the list.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Click on Apply above to activate port forwarding.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/7cc5e/Netgear_01en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGu3P1aLGEv/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQACBBEQEzL/2gAIAQEAAQUCju2CmqgoHNDH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQIf/aAAgBAgEBPwFsM//EABwQAAIBBQEAAAAAAAAAAAAAAAABAhARMUFxkf/aAAgBAQAGPwJ3jFGvDCJ9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABIRExcbHx/9oACAEBAAE/IdW8TyI4IHzS+t4W3KNI/9oADAMBAAIAAwAAABDv7//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPxA1f//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxA6ha3/xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhQZExgf/aAAgBAQABPxCcsUxqS7okqs7mUHp5SKEiaN8FHi4f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/e4706/Netgear_01en.avif 230w", "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/d1af7/Netgear_01en.avif 460w", "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/884e7/Netgear_01en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/a0b58/Netgear_01en.webp 230w", "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/bc10c/Netgear_01en.webp 460w", "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/d3be9/Netgear_01en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/e83b4/Netgear_01en.jpg 230w", "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/e41a8/Netgear_01en.jpg 460w", "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/7cc5e/Netgear_01en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9d0d9aef3b9bcdaff6cdc403c649a6de/7cc5e/Netgear_01en.jpg",
              "alt": "Portforwarding Netgear",
              "title": "Portforwarding Netgear",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`You will need to change both the start- and end-port to the port you want to operate your camera on. Click on `}<strong parentName="p">{`Edit Service`}</strong>{` to customize the service.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb84f1e428cb5884931696366382e61a/7cc5e/Netgear_02en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABaenVaYOhL//EABsQAAEFAQEAAAAAAAAAAAAAAAABAgQREhMy/9oACAEBAAEFAo7uiU0y0gecpdIf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQIf/aAAgBAgEBPwFsM//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAjGREBFx/9oACAEBAAY/AjtjQoFKBSf3P//EABwQAQACAQUAAAAAAAAAAAAAAAEAERAhMUFhsf/aAAgBAQABPyEWoDWhDGjVbTh5NxU6Cf/aAAwDAQACAAMAAAAQ7+//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QNX//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EO0LW//EAB4QAAIBAwUAAAAAAAAAAAAAAAERACExQVFxkdHx/9oACAEBAAE/EBlphbRDy4kGTcOo23CgJA6lYA01HGJ5U//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb84f1e428cb5884931696366382e61a/e4706/Netgear_02en.avif 230w", "/en/static/fb84f1e428cb5884931696366382e61a/d1af7/Netgear_02en.avif 460w", "/en/static/fb84f1e428cb5884931696366382e61a/884e7/Netgear_02en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb84f1e428cb5884931696366382e61a/a0b58/Netgear_02en.webp 230w", "/en/static/fb84f1e428cb5884931696366382e61a/bc10c/Netgear_02en.webp 460w", "/en/static/fb84f1e428cb5884931696366382e61a/d3be9/Netgear_02en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb84f1e428cb5884931696366382e61a/e83b4/Netgear_02en.jpg 230w", "/en/static/fb84f1e428cb5884931696366382e61a/e41a8/Netgear_02en.jpg 460w", "/en/static/fb84f1e428cb5884931696366382e61a/7cc5e/Netgear_02en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb84f1e428cb5884931696366382e61a/7cc5e/Netgear_02en.jpg",
              "alt": "Portforwarding Netgear",
              "title": "Portforwarding Netgear",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`You can now choose a service name, set the protocol to TCP and choose your camera port for both the starting port and ending port. for your first camera the ports are `}<em parentName="p">{`8081`}</em>{`, for your second camera use the ports `}<em parentName="p">{`8082`}</em>{` etc.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d9d92f1d5a0263f81f670224b0c602ff/7cc5e/Netgear_03en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGy3O1qLGEv/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEEAhASEzL/2gAIAQEAAQUCjVbUkjFEDm3/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAXEQADAQAAAAAAAAAAAAAAAAABAhAh/9oACAECAQE/AWwz/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMRARcf/aAAgBAQAGPwJ7jFFIpE+5/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAERECGhsdHx/9oACAEBAAE/IZSwTiyG+B4pitV//9oADAMBAAIAAwAAABBfL//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGf/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QOoWt/8QAHhAAAwABBAMAAAAAAAAAAAAAAAERITFBUWFx0eH/2gAIAQEAAT8QkfJHzq70wGftfQ/lR6IiqcV8D1P/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9d92f1d5a0263f81f670224b0c602ff/e4706/Netgear_03en.avif 230w", "/en/static/d9d92f1d5a0263f81f670224b0c602ff/d1af7/Netgear_03en.avif 460w", "/en/static/d9d92f1d5a0263f81f670224b0c602ff/884e7/Netgear_03en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d9d92f1d5a0263f81f670224b0c602ff/a0b58/Netgear_03en.webp 230w", "/en/static/d9d92f1d5a0263f81f670224b0c602ff/bc10c/Netgear_03en.webp 460w", "/en/static/d9d92f1d5a0263f81f670224b0c602ff/d3be9/Netgear_03en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9d92f1d5a0263f81f670224b0c602ff/e83b4/Netgear_03en.jpg 230w", "/en/static/d9d92f1d5a0263f81f670224b0c602ff/e41a8/Netgear_03en.jpg 460w", "/en/static/d9d92f1d5a0263f81f670224b0c602ff/7cc5e/Netgear_03en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d9d92f1d5a0263f81f670224b0c602ff/7cc5e/Netgear_03en.jpg",
              "alt": "Portforwarding Netgear",
              "title": "Portforwarding Netgear",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h3>
    <p>{`Click `}<strong parentName="p">{`Apply`}</strong>{` to activate the service.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9be603dd3533a684ec4e65b97e80ed47/7cc5e/Netgear_04en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGu7N1aKOS//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAECBBESEzL/2gAIAQEAAQUCjv2JTSmkDnFCj//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABcRAAMBAAAAAAAAAAAAAAAAAAECECH/2gAIAQIBAT8BbDP/xAAcEAACAQUBAAAAAAAAAAAAAAAAAQIQETFBcZH/2gAIAQEABj8Cd4xRrwwifa//xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhUUGx8f/aAAgBAQABPyG1aJzCFoDyRE6rh0ZLCNI//9oADAMBAAIAAwAAABA47//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPxA1f//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxA6hbf/xAAcEAACAQUBAAAAAAAAAAAAAAABEQAhMUFRkXH/2gAIAQEAAT8QQvIDTRDyTEgz+hHW5YpA7Io1PWIm3Kf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9be603dd3533a684ec4e65b97e80ed47/e4706/Netgear_04en.avif 230w", "/en/static/9be603dd3533a684ec4e65b97e80ed47/d1af7/Netgear_04en.avif 460w", "/en/static/9be603dd3533a684ec4e65b97e80ed47/884e7/Netgear_04en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9be603dd3533a684ec4e65b97e80ed47/a0b58/Netgear_04en.webp 230w", "/en/static/9be603dd3533a684ec4e65b97e80ed47/bc10c/Netgear_04en.webp 460w", "/en/static/9be603dd3533a684ec4e65b97e80ed47/d3be9/Netgear_04en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9be603dd3533a684ec4e65b97e80ed47/e83b4/Netgear_04en.jpg 230w", "/en/static/9be603dd3533a684ec4e65b97e80ed47/e41a8/Netgear_04en.jpg 460w", "/en/static/9be603dd3533a684ec4e65b97e80ed47/7cc5e/Netgear_04en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9be603dd3533a684ec4e65b97e80ed47/7cc5e/Netgear_04en.jpg",
              "alt": "Portforwarding Netgear",
              "title": "Portforwarding Netgear",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The rule is active after you applied the settings.`}</p>
    <p>{`To be able to access the Flash Video as well, there has to be set a second Port Forwarding. Therefore, just follow the manual of this step, with the only difference to set the Flash (RTMP) Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step:`}</p>
    <h2 {...{
      "id": "step-iii-the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii-the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III: The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      